import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BaseBlocksRender from "../components/blocks/base-blocks-renderer"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BlockHeader from "../components/blocks/block-header"
import BaseSection from "../components/base-section"
import BlockSectionTitle from "../components/blocks/block-section-title"
import BlockNestedIconCard from "../components/blocks/block-nested-icon-card"

const MutualistasPage = () => {
  const { strapiMutualista } = useStaticQuery(graphql`
    query {
      strapiMutualista {
        defaultSeo {
          metaTitle
          metaDescription
          shareImage {
            id
            url
            localFile {
              url
            }
          }
        }
        header {
          title
          cols
          isLarge
          cover {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        titleCard {
          sectionTitle
          highlight
          level
          note
        }
        cards {
          id
          columns
          cards {
            fixedHeight
            hasBg
            iconSubtitle
            id
            cover {
              url
              localFile {
                url
              }
              alternativeText
            }
          }
        }
        blocks {
          __typename
          ...BlockPriceGrid
          ...BaseSectionTitle
          ... on STRAPI__COMPONENT_SHARED_CHARGE_LIST {
            id
            titleCharge
            titleRate
            charges {
              id
              rate
              charge
            }
          }
        }
      }
    }
  `)

  const { header, titleCard, cards } = strapiMutualista

  return (
    <Layout>
      <Seo seo={strapiMutualista.defaultSeo} />
      <BlockHeader data={header} />
      <BaseSection>
        <BlockSectionTitle data={titleCard} />
      </BaseSection>
      <BaseSection>
        <BlockNestedIconCard data={cards} />
      </BaseSection>
      <BaseBlocksRender blocks={strapiMutualista.blocks} />
    </Layout>
  )
}

export default MutualistasPage
